@import '~antd/dist/antd.less';

@font-face {
  font-family: 'bebasNeueRegular';
  src: url('fonts/bebasNeue/BebasNeue-Regular.eot'); /* IE9 Compat Modes */
  src: url('fonts/bebasNeue/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('fonts/bebasNeue/BebasNeue-Regular.woff') format('woff'), /* Modern Browsers */
      url('fonts/bebasNeue/BebasNeue-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
      
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bebasNeueRegular {
  font-family: "bebasNeueRegular";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #59a947;@body-background: #999999;